<template>
	<nav class="navbar fixed-top pl-0">
		<div class="d-flex justify-content-between align-items-center w-100">
			<!-- Parte esquerda e central -->
			<div class="navbar-left d-flex align-items-center h-100">
				<!-- Trigger menu lateral e nome do sistema -->
				<div
					class="user-select-none init pr-3 d-flex justify-content-center align-items-center"
					:class="showBackButton ? 'mr-0 ' : 'mr-3'"
					style="min-height: 64px"
					ref="navbar-header"
				>
					<transition name="zoom-x">
						<div
							id="container-menu"
							class="toggle-menu p-3 mr-2 h-100 d-flex justify-content-center align-items-center"
							data-toggle="tooltip"
							data-placement="bottom"
							title="Mostrar/ocultar barra lateral"
							@click="menuButtonClicked"
							v-if="usuario"
						>
							<MenuIcon color="white" />
						</div>
					</transition>
					<div class="d-flex align-items-center brand">
						<h1 id="logo-tcs" class="mb-0 logo">TCS</h1>
						<div class="produto d-flex justify-content-center align-items-center">
							<h2 id="pipe-tcs" class="mb-0 px-2">|</h2>
							<h2 id="mmi-tcs" class="mb-0">MMI</h2>
						</div>
					</div>
				</div>

				<!-- Botão de voltar -->
				<div
					class="user-select-none go-back px-2 d-flex justify-content-center align-items-center"
					data-cy="Voltar"
					@click="backButtonClicked"
					title="Voltar"
					data-toggle="tooltip"
					data-placement="bottom"
					:class="{ 'force-hidden': !showBackButton }"
				>
					<ArrowLeftIcon color="#6dcff6" />
				</div>

				<h2 class="module mb-0 pl-1">Mapas</h2>
				<h3 class="secao mb-0 px-3">|</h3>

				<!-- Título da página -->
				<h2 class="mb-0 secao" data-cy="Titulo-pagina">{{ titulo }}</h2>
			</div>

			<!-- Parte direita -->

			<!-- Versão, apenas no login -->
			<div
				class="h-100 mr-2 px-2"
				v-if="!usuario && $route.name === 'login'"
			>
				<ul
					tabindex="1"
					class="btn btn-light px-2 versao-badge"
					@click="changelog = true"
					@keypress="changelog = true"
					title="Changelog"
					data-toggle="tooltip"
					data-placement="bottom"
				>
					<li>WEB: {{ versaoWeb }}</li>
					<li>API: {{ versaoApi }}</li>
				</ul>
			</div>

			<!-- Parte direita -->
			<div
				class="navbar-right d-flex align-items-center h-100 pr-2 py-1"
				v-if="usuario"
			>
				<!-- <img class="logo-cliente" src="/logos/ArcelorMittal.svg" height="50"/> -->

				<div class="h-100 apps-btn d-flex align-items-center">
					<a class="infoTurno" title="Turno Atual" v-b-popover.hover.bottom.html= turnoAtualTemplate
						data-placement="bottom" data-popover>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
							stroke="#696969" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="feather feather-clock">
							<circle cx="12" cy="12" r="10"></circle>
							<polyline points="12 6 12 12 16 14"></polyline>
						</svg>
					</a>
				</div>

				<div class="h-100 apps-btn" tabindex="0">
					<div class="only-active">
						<div class="arrow-wrapper">
							<div class="arrow"></div>
						</div>
						<div class="apps-list">
							<a v-for="(modulo, idx) in modulos" :key="idx" :href="modulo.url" class="apps-list-item">
								{{ modulo.nome }}
							</a>
						</div>
					</div>
					<div>
						<div class="apps text-center">
							<AppsIcon />
						</div>
					</div>
				</div>

				<div class="user p-1 d-flex" @click="perfilUsuario()">
					<div class="avatar mr-2 text-white">
						<UserIcon name="user" label="Ícone de Usuário" />
					</div>

					<div class="d-flex align-items-center">
						<div class="wrapper">
							<p class="name mb-0 cut-text d-block text-truncate" style="max-width: 100px;" :title="nomeUsuarioCompleto">
								<b>{{ usuario || "Desconectado" }}</b>
							</p>
						</div>
					</div>
				</div>

				<div
					class="p-3 d-flex"
					@click="logout()"
				>
					<LogOutIcon />
				</div>
			</div>
		</div>

		<!-- Modal de changelog -->
		<changelog v-if="changelog" @close="changelog = false" />
	</nav>
</template>

<script>
	const PLACEHOLDER_TITLE = "Mapas";

	import { debounce } from "@/helpers/common";
	import Changelog from "./NavBar/Changelog.vue";

	import { AuthService } from "../services/auth";
	import comumService from "../services/comum";
	import { TurnosService } from "../services/turnos";

	const turnosService = new TurnosService();

	export default {
		components: { Changelog },

		props: {
			showBackButton: {
				type: Boolean,
				default: false
			}
		},

		data () {
			return {
				titulo: PLACEHOLDER_TITLE,
				maxWidthIntervalUpdates: 0,
				resFn: null,
				collapsed: true,
				usuario: "",
				nomeUsuarioCompleto: "",
				notificationCount: 0,
				versaoWeb: process.env.VUE_APP_VERSION,
				versaoApi: "-",
				changelog: false,
				modulos: {},
				turnos: [],
				turnoAtualTemplate: "",

				authService: new AuthService()
			};
		},

		methods: {
			menuButtonClicked () {
				this.$emit("menu-button-click");
			},

			backButtonClicked () {
				this.$emit("back-button-click");
			},

			toggleCollapsed () {
				this.collapsed = !this.collapsed;
			},

			setUser () {
				const storage = this.authService.getModuleStorage();
				this.usuario = (storage && storage.nomeUsuario) || "";
				this.nomeUsuarioCompleto = storage.nomeUsuarioCompleto;
			},

			logout () {
				this.authService.logout();
			},

			perfilUsuario () {
				window.location.href = `${this.modulos.comum.url}#!/perfilUsuario`;
			}
		},

		watch: {
			"$route.meta.title" (newVal) {
				this.titulo = newVal || PLACEHOLDER_TITLE;
			}
		},

		async mounted () {
			this.setUser();
			this.resFn = debounce(() => {
				const hWidth = getComputedStyle(
					this.$refs["navbar-header"],
					null
				).getPropertyValue("width");

				this.$emit(
					"change-max-width",
					parseFloat(hWidth.replace("px", ""))
				);
			}, 300).bind(this);

			// Atualiza maxWidth ao redimensionar a janela do navegador
			window.addEventListener("resize", this.resFn);

			// Atualiza maxWidth de tempos em tempo nos primeiros minutos por causa do carregamento de fontes
			const UPDATE_INTERVAL = 5000; // ms
			const MAX_INTERVAL_UPDATES = Math.ceil((1 * 60 * 1000) / UPDATE_INTERVAL); // 1 minuto

			(
				function updateMaxWidth () {
					this.resFn();
					if (++this.maxWidthIntervalUpdates < MAX_INTERVAL_UPDATES)
						setTimeout(updateMaxWidth.bind(this), UPDATE_INTERVAL);
				}
			).bind(this)();

			// axios.get(api.v1.info.versao).then(
			// 	(res) => this.versaoApi = res.data.api || "-",
			// 	() => this.versaoApi = "-"
			// );

			this.modulos = await comumService.getModules();

			// Obtém o turno ataul e monta o popup
			const turnoAtualPop = await turnosService.obterTurnoAtual();
			this.turnoAtualTemplate = `
					Data do turno atual: ${turnoAtualPop.dataTurno} </br>
					Turno atual: ${turnoAtualPop.turno}º </br>
					Período: ${turnoAtualPop.dataInicio} ${turnoAtualPop.inicio} - ${turnoAtualPop.dataFim} ${turnoAtualPop.fim}
				`;
			const dataTurnoSeparada = turnoAtualPop.dataTurno.split("/");
			const dataTurnoSistema = dataTurnoSeparada[2] + "-" + dataTurnoSeparada[1] + "-" + dataTurnoSeparada[0];
			localStorage.setItem("dataTurnoSistema", dataTurnoSistema);
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.resFn);
		}
	};
</script>

<style scoped>
	.force-hidden {
		display: none !important;
	}

	.navbar .configs,
	.navbar .apps {
		fill: none;
	}

	.navbar .user .avatar {
		background-color: #696969;
		padding: 0 12px 0 12px;
		border-radius: 5px;
		width: 50px;
		height: 48px;
		line-height: 48px;
	}

	.navbar .user .avatar svg {
		width: 26px;
		height: 26px;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.versao-badge {
		margin: 0;
		font-family: monospace;
		padding: 0;
		display: inline-block;
	}

	.versao-badge {
		vertical-align: initial;
	}

	.collapsed-menu {
		display: none;
	}

	.collapsed-menu svg {
		color: dimgray;
	}

	.secao {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.navbar .navbar-left {
		position: relative;
		flex: 1;
		min-width: 0;
	}

	.navbar .produto h2 {
		font-weight: 600;
	}

	.navbar .secao {
		font-weight: 300;
	}

	.navbar .module {
		font-weight: 600;
		color: #6DCFF6;
	}

	.apps {
		width: 56px;
	}

	@media screen and (max-width: 790px) {
		.collapsed-menu {
			display: flex;
		}

		.versao-badge {
			font-size: 10px !important;
		}

		.navbar .secao {
			display: none;
		}
	}

	@media screen and (max-width: 620px) {
		.module, .user {
			display: none !important;
		}

		#logo-tcs, #pipe-tcs {
			display: none;
		}

		#mmi-tcs {
			align-self: center;
			padding-right: 0px;
		}

		#container-menu {
			padding-right: 0px !important;
			padding-left: 0px !important;
		}
	}

	@media screen and (max-width: 760px) {
		.logo-cliente {
			display: none !important;
		}
	}

	@media (max-width: 595px) {
		.infoTurno{
			display: none !important;
		}
	}

	.p-3 {
		border-radius: 5px;
		cursor: pointer;
		box-sizing: content-box;
		border: 1px solid rgba(0, 0, 0, 0);
		padding: 0.375rem 0.75rem;
	}

	.p-3:hover, .p-3:active {
		border: 1px solid #ddd;
	}
</style>
